"use client"
import { Page } from "@tc/ui-shared/components"
import dynamic from "next/dynamic"
import { useRouter } from "next/navigation"
import { useEffect } from "react"
import { getPageUrl, useTeoPermission } from "../../components"
const TemplateQuickSearch = dynamic(() =>
  import("../../components/TemplateQuickSearch").then(
    (mod) => mod.TemplateQuickSearch,
  ),
)

export default function Index() {
  const { permissionCodes } = useTeoPermission([])

  // TODO move the page url redirection to middleware once middleware can read user permission. Read yaml file (process.cwd) is not supported in the Edge Runtime
  const router = useRouter()
  useEffect(() => {
    const pageUrl = getPageUrl(permissionCodes)
    router.push(pageUrl)
  }, [])
  return (
    <Page
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    ></Page>
  )
}
