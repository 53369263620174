import { Box, BoxProps } from "@mui/material"

export interface SectionRowProps extends BoxProps {
  children: React.ReactNode
  columnPerRow?: number
  spacing?: number
  hover?: boolean
}
export const SectionRow = (props: SectionRowProps) => {
  const { columnPerRow = 2, spacing = 2, hover, sx, ...boxProps } = props
  return (
    <Box
      data-testid="section-row"
      sx={{
        display: "grid",
        gridTemplateColumns: ` repeat(${columnPerRow}, minmax(280px, 0fr));`,
        gap: spacing,
        "&:hover": hover
          ? {
              bgcolor: "background.default",
            }
          : {},
        ...sx,
      }}
      {...boxProps}
    >
      {props.children}
    </Box>
  )
}
