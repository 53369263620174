export interface FormProps {
  children: React.ReactNode
  onSubmit?: (event: React.FormEvent) => void
}
export const Form = (props: FormProps) => {
  const { children, onSubmit } = props
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit?.(e)
      }}
      noValidate={true}
    >
      {children}
    </form>
  )
}
