import {
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  TypographyProps,
} from "@mui/material"
import React, { Fragment } from "react"

export interface SectionProps<
  TitleTypographyComponent extends React.ElementType = "span",
> {
  children: React.ReactNode
  onSubmit?: (event: React.FormEvent) => void
  title?: string
  titleTypographyProps?: TypographyProps<
    TitleTypographyComponent,
    {
      component?: TitleTypographyComponent
    }
  >
  actions?: React.ReactNode
}
export type Ref = React.ReactNode | Element | null
const SectionBody = (props: SectionProps) => {
  const { children, title, titleTypographyProps, actions } = props
  return (
    <Fragment>
      <CardHeader title={title} titleTypographyProps={titleTypographyProps} />
      <Divider />
      <CardContent>{children}</CardContent>
      {actions && (
        <>
          <Divider />
          <CardActions sx={{ p: 2 }}>{actions}</CardActions>
        </>
      )}
    </Fragment>
  )
}
export const Section = (props: SectionProps) => {
  const { onSubmit } = props
  return (
    <Paper variant="outlined" data-testid="section" sx={{ minWidth: "700px" }}>
      {onSubmit && (
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit?.(e)
          }}
          noValidate={true}
        >
          <SectionBody {...props}></SectionBody>
        </form>
      )}
      {!onSubmit && <SectionBody {...props}></SectionBody>}
    </Paper>
  )
}
