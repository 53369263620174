import { Stack, StackProps } from "@mui/material"

export interface PageContentProps extends StackProps {
  children?: React.ReactNode
}
export const PageContent = ({ children, ...props }: PageContentProps) => {
  return (
    <Stack padding={2} {...props}>
      {children}
    </Stack>
  )
}
