export enum PermissionCode {
  _, // All

  // templates
  template_search_view,
  template_create_update,
  template_download_document,
  template_upload_document,
  template_detail_history,

  // business rules
  business_rules_search_view,
  business_rules_update,
  business_rules_upload_document,
  business_rules_download_document,

  // Imports
  imports_search_view,
  imports_download_xml,
  imports_status_update,

  // Customs Permit Numbers
  customs_permit_numbers_upload_document,
}
