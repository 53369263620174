import { Button, Container, Divider, Stack, Typography } from "@mui/material"
import { Link } from "../Link"
import { Page } from "../Page"

export const NotFound = () => {
  return (
    <Page
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container fixed sx={{ width: "580px", textAlign: "center" }}>
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          divider={<Divider flexItem orientation="vertical" />}
          sx={{
            mt: -5,
            mb: 4,
          }}
        >
          <Typography variant="h5" data-testid="error404" component={"strong"}>
            Error 404
          </Typography>
          <Typography variant="h6" data-testid="errorContent">
            The page you requested could not be found.
          </Typography>
        </Stack>

        <Link href="/" prefetch custom>
          <Button data-testid="goBackBtn" variant="contained">
            Go back to Home
          </Button>
        </Link>
      </Container>
    </Page>
  )
}
