import { Button, ButtonProps, CircularProgress } from "@mui/material"

export interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
}

export const LoadingButton = (props: LoadingButtonProps) => {
  const { loading, ...buttonProps } = props
  if (loading) {
    return (
      <Button {...buttonProps} disabled={true}>
        <CircularProgress size={25} />
      </Button>
    )
  } else {
    return <Button {...buttonProps} />
  }
}
