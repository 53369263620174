"use client"

import { Fragment, useContext } from "react"
import { PageUrlSetting } from "./PageUrlSetting"
import { PermissionCode } from "./PermissionCodes"
import { TeoPermissionContext } from "./TeoPermissionProvider"

export interface TeoPermissionProps {
  PermissionCodes: PermissionCode[]
  children: React.ReactNode
}

// to control entire page
export const TeoPermissionPage = ({
  PermissionCodes,
  children,
}: TeoPermissionProps): React.ReactNode => {
  const hasPermission = useTeoPermission(PermissionCodes)
  if (hasPermission.allow) {
    return <Fragment>{children}</Fragment>
  }
  window.location.replace(`${window.location.origin}/not-found`)

  return <></>
}

export const TeoPermission = ({
  PermissionCodes,
  children,
}: TeoPermissionProps): React.ReactNode => {
  const hasPermission = useTeoPermission(PermissionCodes)
  if (hasPermission.allow) {
    return <Fragment>{children}</Fragment>
  }
  return <></>
}

export const useTeoPermission = (codes: PermissionCode[]) => {
  const { permissionCodes, hasPermission } = useContext(TeoPermissionContext)

  if (permissionCodes.includes(PermissionCode[PermissionCode._])) {
    return { allow: true, hasPermission, permissionCodes }
  }

  const allow = hasPermission(codes)
  return { allow: allow, hasPermission, permissionCodes }
}

export const getPageUrl = (permissionCodes: string[]) => {
  const pageUrls: string[] = []
  permissionCodes.map((permissionCode: string) => {
    const setting = PageUrlSetting.find(
      (setting) => setting.permissionCode == PermissionCode[permissionCode],
    )
    if (setting) pageUrls.splice(setting.order, 0, setting.url)
  })

  if (pageUrls.length > 0) {
    return pageUrls[0]
  } else {
    return "/not-found"
  }
}
