"use client"

import { createContext, useCallback, useState } from "react"
import { intersection, isEmpty } from "lodash"
import { PermissionCode } from "./PermissionCodes"

export interface TeoPermissionContextProps {
  permissionCodes: string[]
  hasPermission: (codes: PermissionCode[]) => boolean
}
export interface TeoPermissionProviderProps {
  children: React.ReactNode
  permissionCodes: string[]
}

export const TeoPermissionContext = createContext<TeoPermissionContextProps>({
  permissionCodes: [],
  hasPermission: () => false,
})

export const TeoPermissionProvider = ({
  permissionCodes,
  children,
}: TeoPermissionProviderProps) => {
  const [permissions] = useState(permissionCodes)

  const hasPermission = useCallback(
    (codes: PermissionCode[]) => {
      if (permissions.indexOf(`_`) >= 0) return true
      if (isEmpty(codes)) return false // if no flags are passed in, default to true
      return !isEmpty(
        intersection(
          permissions,
          (codes || []).map((code) => PermissionCode[code]),
        ),
      )
    },
    [permissions],
  )

  return (
    <TeoPermissionContext.Provider
      value={{
        permissionCodes: permissions,
        hasPermission,
      }}
    >
      {children}
    </TeoPermissionContext.Provider>
  )
}
