import {
  businessRuleSearchPage,
  importCertificateSearchPage,
  templateSearchPage,
} from "@tc/ui-shared/utils"
import { PermissionCode } from "./PermissionCodes"

export const PageUrlSetting = Object.freeze([
  {
    permissionCode: PermissionCode._,
    url: importCertificateSearchPage,
    order: 0,
  },
  {
    permissionCode: PermissionCode.imports_search_view,
    url: importCertificateSearchPage,
    order: 1,
  },
  {
    permissionCode: PermissionCode.template_search_view,
    url: templateSearchPage,
    order: 2,
  },
  {
    permissionCode: PermissionCode.business_rules_search_view,
    url: businessRuleSearchPage,
    order: 3,
  },
])
