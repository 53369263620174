import { Stack, Typography } from "@mui/material"
import React from "react"

export interface LabelValueProps {
  label: string | React.ReactNode
  value: string | React.ReactNode
}
export const LabelValue = (props: LabelValueProps) => {
  const { label, value } = props
  return (
    <Stack>
      <Typography
        color="text.secondary"
        component="div"
        data-testid="test-label"
      >
        {label}
      </Typography>
      <Typography variant="body2" component="div" data-testid="test-value">
        {value}
      </Typography>
    </Stack>
  )
}
